<template>
    <div>
      <div class="row">
  
        <div class="col-12">
            <div class="card">
                <div class="card-body">

                    <p><strong>Name: </strong>{{ checklist.name }}</p>
                    <p><strong>Description: </strong>{{checklist.description}}</p>

                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    Question
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="question in checklist.questions" :key="'q-' + question.id">
                                <td>
                                    {{question.question}}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-danger" @click="removeQuestion(question)">Delete</button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-center">
                                    <button class="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#addQuestionModal">Add Question</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

        <!-- Add Checklist Question -->
        <div class="modal fade" id="addQuestionModal" tabindex="-1" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add Question</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="max-height: 500px;">
                        <div class="row">

                            <div class="col-12">

                                <div class="form-group col-md-12">
                                    <label for="question">Question</label>
                                    <textarea class="form-control" v-model="question" id="question" rows="3"></textarea>
                                </div>

                                <br/>

                                <button class="btn btn-sm btn-success float-end" @click="addQuestion">Add Question</button>

                            </div>
        
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='clostViewChecklistModal'>Close</button>
                    </div>
                </div>
            </div>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  /* global $ */
  import axios from 'axios';
  export default {
    name: 'admin.checklists.view',
    props: ['id'],
    data(){
      return {
        checklist: {
          name: "",
          description: ""
        },
        edit: false,
        updating: false,
        question: ""
      }
    },
    mounted(){
      this.loadChecklist()
    },
    methods: {
      loadChecklist(){
        axios.get(`${process.env.VUE_APP_API_URL}/v1/checklist/${this.id}`)
        .then(response => {
          this.checklist = response.data.checklist;
        })
        .catch(error => {
          this.$error("Failed to load checklist.", error);
        })
      },
      addQuestion(){
        axios.post(`${process.env.VUE_APP_API_URL}/v1/checklist/${this.id}/item`, {
          question: this.question
        })
        .then(() => {
          this.$success("Question added successfully.");
          $("#addQuestionModal").modal("hide");
          this.question = "";
          this.loadChecklist();
        })
        .catch(error => {
          this.$error("Failed to add question.", error);
        })
      },
      removeQuestion(question){
        axios.delete(`${process.env.VUE_APP_API_URL}/v1/checklist/${this.id}/item/${question.id}`)
        .then(() => {
          this.$success("Question removed successfully.");
          this.loadChecklist();
        })
        .catch(error => {
          this.$error("Failed to remove question.", error);
        })
      }
    }
  }
  </script>